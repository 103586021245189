// Voir : https://fontsource.org/fonts/source-sans-pro
import '@fontsource/source-sans-pro/latin-400.css';
import '@fontsource/source-sans-pro/latin-400-italic.css';
import '@fontsource/source-sans-pro/latin-700.css';

//
// Application
//

import './components/scroll_button';

import 'flowbite';
import { Application } from '@hotwired/stimulus';

import RevealController from './controllers/reveal';

window.Stimulus = Application.start();

window.Stimulus.register('reveal', RevealController);
